import {AiOutlineTwitter} from 'react-icons/ai'
import {IoLogoFacebook} from 'react-icons/io'
import {AiFillGithub} from 'react-icons/ai'
import { FaLinkedin } from "react-icons/fa";


const data = [
    {
        id: 1, link: 'https://github.com/ayoub-essarghini', icon: <AiFillGithub/>
    },
    {
        id: 2, link: 'https://www.linkedin.com/in/ayoub-es-sarghini-839973212', icon: <FaLinkedin/>
    },
    {
        id: 3, link: 'https://twitter.com/AyoubEssarghin1?t=cdyU7FYm0qKYzDavr3HnUA&s=08', icon: <AiOutlineTwitter/>
    },
    {
        id: 4, link: 'https://www.facebook.com/profile.php?id=100078121976249', icon: <IoLogoFacebook/>
    },
   
]

export default data;