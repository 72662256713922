import "./floating-nav.css";
import { AiFillHome } from "react-icons/ai";
import { AiFillInfoCircle } from "react-icons/ai";
import { MdBusinessCenter } from "react-icons/md";
import { GiSkills } from "react-icons/gi";
import { FaNetworkWired } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { MdReviews } from "react-icons/md";
import React, { useState, useEffect } from 'react';

const FloatingNav = () => {

    const [activeItem, setActiveItem] = useState(1);

    const handleItemClick = (itemId) => {
     
  
      setActiveItem(itemId);
     
    };

    useEffect(() => {
        const handleScroll = () => {
            const sectionIds = ['home', 'about', 'services', 'skills','portfolio','testimonials', 'contact'];  // IDs of the sections
    
          const active = sectionIds.find((sectionId) => {
            const section = document.getElementById(sectionId);
            const { top, bottom } = section.getBoundingClientRect();
            const windowHeight = window.innerHeight;
            const threshold = 0.4; // Adjust the threshold value as needed (0.5 means the section is at least 50% visible)
    
            return top <= windowHeight * threshold && bottom >= windowHeight * threshold;
          });
    
          setActiveItem(active || '');
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
    
      
  return (
    <section id="floting__nav">
      <div className="floting_nav_items">
        <a href="#home"  className={`nav_icon ${activeItem === 'home' ? "active " : ""}`} onClick={()=>handleItemClick('home')}>
          {" "}
          <AiFillHome />
        </a>
        <a href="#about" className={`nav_icon ${activeItem === 'about' ? "active " : ""}`} onClick={()=>handleItemClick('about')}>
          {" "}
          <AiFillInfoCircle />
        </a>
        <a href="#services" className={`nav_icon ${activeItem === 'services' ? "active " : ""}`} onClick={()=>handleItemClick('services')}>
          {" "}
          <MdBusinessCenter />
        </a>
        <a href="#skills" className={`nav_icon ${activeItem === 'skills' ? "active " : ""}`} onClick={()=>handleItemClick('skills')}>
          {" "}
          <GiSkills />
        </a>
        <a href="#portfolio" className={`nav_icon ${activeItem === 'portfolio' ? "active " : ""}`} onClick={()=>handleItemClick('portfolio')}>
          {" "}
          <FaNetworkWired />
        </a>
        <a href="#testimonials" className={`nav_icon ${activeItem === 'testimonials' ? "active " : ""}`} onClick={()=>handleItemClick('testimonials')}>
          {" "}
          <MdReviews />
        </a>
        <a href="#contact" className={`nav_icon ${activeItem === 'contact' ? "active " : ""}`} onClick={()=>handleItemClick('contact')}>
          {" "}
          <MdEmail />
        </a>
      </div>
    </section>
  );
};

export default FloatingNav;
