import php from '../../assets/images/skills/php.png';
import css from '../../assets/images/skills/css.png';
import js from '../../assets/images/skills/js.png';
import laravel from '../../assets/images/skills/laravel.png';
import html from '../../assets/images/skills/html.png';
import bootstrap from '../../assets/images/skills/bootstrap.png';
import tailwind from '../../assets/images/skills/tailwind.png';
import mysql from '../../assets/images/skills/mysql.png';
import react_img from '../../assets/images/skills/react.png';
import java from '../../assets/images/skills/java.png';
import sqlite from '../../assets/images/skills/sqlite.png';
import c from '../../assets/images/skills/c.png';


const data = [


    {
        id:1,
        image:html,
        skill_name: "Html",
        skill_number: "90",
        prc:"html",
        category:"front"
    },
    {
        id:2,
        image:css,
        skill_name: "CSS",
        skill_number: "80",
        prc:"css",
        category:"front"
    },
    {
        id:3,
        image:bootstrap,
        skill_name: "bootstrap",
        skill_number: "80",
        prc:"bootstrap",
        category:"front"
    },
    {
        id:4,
        image:tailwind,
        skill_name: "Tailwind css",
        skill_number: "50",
        prc:"tailwind",
        category:"front"
    },
    {
        id:5,
        image:js,
        skill_name: "Java script",
        skill_number: "75",
        prc:"js",
        category:"front"
    },
    {
        id:6,
        image:php,
        skill_name: "php",
        skill_number: "75",
        prc:"php",
        category:"back"
    },
    {
        id:7,
        image:laravel,
        skill_name: "Laravel",
        skill_number: "70",
        prc:"laravel",
        category:"back"
    },
    {
        id:8,
        image:mysql,
        skill_name: "MySQL",
        skill_number: "70",
        prc:"msql",
        category:"back"
    },
    {
        id:9,
        image:react_img,
        skill_name: "React js",
        skill_number: "65",
        prc:"react_js",
        category:"front"
    },
    {
        id:10,
        image:java,
        skill_name: "Java",
        skill_number: "70",
        prc:"java",
        category:"mobile"
    },

    {
        id:9,
        image:sqlite,
        skill_name: "SQLite",
        skill_number: "75",
        prc:"sqlite",
        category:"mobile"
    },
    {
        id:10,
        image:c,
        skill_name: "C",
        skill_number: "60",
        prc:"C",
        category:"other"
    },

]

export default data;