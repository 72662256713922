import React from 'react';
import './index.css'
import Navbar from './sections/navbar/Navbar';
import Header from './sections/header/Header';
import About from './sections/about/About';
import Services from './sections/services/Services';
import Testimonials from './sections/testimonials/Testimonials';

import Contact from './sections/contact/Contact';
import Footer from './sections/footer/Footer';
import Portfolio from './sections/portfolio/Portfolio';
import Skills from './sections/skills/Skills';
import FloatingNav from './sections/floating-nav/FloatingNav';



const App = () => {
    return (
      <main>
        <Navbar/>
      <Header/>
      <About/>
      <Services/>
      <Skills/>
      <Portfolio/>
      <Testimonials/>
      <Contact/>
      <Footer/>
      <FloatingNav/>
      </main>
    );
}

export default App;
