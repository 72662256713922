import "./portfolio.css";
import Menu from "./Menu";

import { AiOutlineLink } from "react-icons/ai";
import React, { useState } from "react";

const Portfolio = () => {
  const [activeItem, setActiveItem] = useState(1);
  const [showAnimatedItems, setShowAnimatedItems] = useState(true);

  const handleItemClick = (itemId) => {
    if (itemId === 1) {
      setItems(Menu);
      setShowAnimatedItems(true);
     
    } else if (itemId === 2) {
      filterItem("Web");
      setShowAnimatedItems(true);
    } else if (itemId === 3) {
      filterItem("Mobile");
      setShowAnimatedItems(true);
    }

    setActiveItem(itemId);
   
  };

  const [items, setItems] = useState(Menu);
  const filterItem = (categoryItem) => {
    const updatedItems = Menu.filter((curElem) => {
      return curElem.category === categoryItem;
    });

    setItems(updatedItems, handleItemClick(1));
  };
  return (
    <section id="portfolio">
      <div className="container portfoli__container">
        <h2 className="section__title">Recent Works</h2>
        
        <div className="work__filters">
          <div className="filter__pager">
            <span
            className={`work__item ${activeItem === 1 ? "active " : ""}`}
            onClick={()=>handleItemClick(1)}
          >
            All
          </span>
          <span
            className={`work__item ${activeItem === 2 ? "active " : ""}`}
            onClick={()=>handleItemClick(2)}
          >
            Web
          </span>
          <span
            className={`work__item ${activeItem === 3 ? "active " : ""}`}
            onClick={()=>handleItemClick(3)}
          >
            Mobile
          </span>
          </div>
          
        </div>

        <div className="work__container grid">
          {showAnimatedItems && items.map((elem) => {
            const { id, image, title,url, category } = elem;
            return (
              <div className="work__card" key={id}>
                <div className="work__thumbnail">
                  <img src={image} alt="" className="work__img" />
                  <div className="work__mask"></div>
                  <span className="work__category">{category}</span>
                  <h3 className="work__title">{title}</h3>
                  <a target={url !== '#' ?'_blank' : '_self'} rel = {url !== '#' ? 'noreferrer' : ''} href={url} className="work__button">
                    <AiOutlineLink />
                  </a>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
