import "./about.css";
import AboutImage from "../../assets/images/profile.jpg";
import CV from "../../assets/cv.pdf";
import video from "../../assets/videos/video2.mp4";
import { HiDownload } from "react-icons/hi";
import Card from "../../components/Card";
import data from "./data";
import { FaPlay } from "react-icons/fa";
import { FaPause } from "react-icons/fa";
import React, { useState, useRef } from "react";

const About = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const play = () => {
    videoRef.current.play();
    setIsPlaying(true);
  };

  const pause = () => {
    videoRef.current.pause();
    setIsPlaying(false);
  };

  const handleVideo = () => {
   
    setIsPlaying(false);
  };

  return (
    <section id="about">
      <div className="container about__container">
        <div className="about__left">
          <div className="cirlce">
            <div className="video__btn shake-button">
              {isPlaying ? (
                <button className="icon" onClick={pause}>
                  {" "}
                  <FaPause />{" "}
                </button>
              ) : (
                <button className="icon" onClick={play}>
                  <FaPlay />
                </button>
              )}
            </div>
            <div className="about__portrait">
              <div className={isPlaying ? "video_cover show" : "video_cover"}>
                <video ref={videoRef} onEnded={handleVideo} src={video}></video>
              </div>

              <img src={AboutImage} alt="" />
            </div>
          </div>
        </div>
        <div className="about__right">
          <h2>About Me</h2>
          <div className="about__cards">
            {data.map((item) => (
              <Card key={item.id} className="about__card">
                <span className="about__card-icon">{item.icon}</span>
                <h5>{item.title}</h5>
                <small> {item.desc} </small>
              </Card>
            ))}
          </div>
          <p>
            My name is Ayoub Essarghini , I'm 25 years old , Full stack |
            Android App developer
          </p>
          <p>
            I Developed Web and Android applications for various industries: -
            Food Ordering App, - E-Commerce App / Shopping App, - Social App, -
            On-Demand App, - Finance App,Quoes App , Delivery App
          </p>
          <a href={CV} download className="btn primary">
            Download CV <HiDownload />{" "}
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
