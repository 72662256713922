// import HeaderImage from "../../assets/images/profile2.jpeg";
import "./header.css";
import React from 'react';
import Lottie from 'react-lottie';
// import loaderfile from '../../assets/animation/loader.json';
import profilefile from '../../assets/animation/me.json';
import data from './data'


const Header = () => {
  // const [imageLoaded, setImageLoaded] = useState(false);



  // const handleImageLoad = () => {
  //   setTimeout(() => {
  //     setImageLoaded(true);
  //   }, 2000)

  // };

  // const handleImageError = () => {
  //   console.error('Error loading image');
  // };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: profilefile,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <header id="home">
      <div className="container header__container">

        <div className="header__profile">

          {/* {!imageLoaded && (
            <div style={{ width: '80%', height: '100%', transform: 'translateX(50px)' }}>
              <Lottie options={defaultOptions} />
            </div>
          )} */}
          {
            <div className="lottie__profile">
              <Lottie options={defaultOptions} />
            </div>
            // <img
            //   onLoad={handleImageLoad}
            //   onError={handleImageError}
            //   src={HeaderImage}
            //   alt="profile"
            // />
          }
          <div className="shadow_img"></div>


        </div>
        <h3>Ayoub Es-sarghini</h3>
        <h4>Full stack & Android developer | 1337 Student (42 Network) </h4>
        <p>
          You are a click away from building your dream website or web app. Send
          me the details of your project for a modern, mobile responsive, highly
          performant website today!
        </p>
        <div className="header__cta">
          <a href="#contact" className="btn primary">Let's Talk</a>
          <a href="#portfolio" className="btn light">My work</a>
        </div>
        <div className="header__socials">
          {
            data.map(item => <a href={item.link} key={item.id} target="_blank" rel="noreferrer">{item.icon}</a>)
          }


        </div>
      </div>
    </header>
  );
};

export default Header;
