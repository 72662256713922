import React, { useEffect, useState } from 'react';

const NumberAnimation = ({ number }) => {
  const [animatedNumber, setAnimatedNumber] = useState(0);

  useEffect(() => {
    const animateNumber = () => {
      const animationDuration = 800; // Duration of the animation in milliseconds
      const animationStep = Math.ceil(number / (animationDuration / 10)); // Increment value for each animation frame
      let currentValue = 0;

      const interval = setInterval(() => {
        if (currentValue >= number) {
          setAnimatedNumber(number);
          clearInterval(interval);
        } else {
          setAnimatedNumber(currentValue);
          currentValue += animationStep;
        }
      }, 10);

      return () => {
        clearInterval(interval);
      };
    };

    animateNumber();
  }, [number]);

  return <span>{animatedNumber}</span>;
};

export default NumberAnimation;