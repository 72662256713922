import "./contact.css";
import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  const [buttonText, setButtonText] = useState("Send");
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const notify_success = () =>
    toast.success("Message Sent Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const notify_error = () =>
    toast.error("Something went wrong try again !", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    setButtonText("Sending..");
    setButtonDisabled(true);

    emailjs
      .sendForm(
        "service_it6s57a",
        "template_o2yz2mh",
        form.current,
        "Ugl9YvF6le4Cu_se_"
      )
      .then(
        (result) => {
          console.log(result.text);

          notify_success();
          setButtonText("Send");
          setButtonDisabled(false);
          e.target.reset();
        },
        (error) => {
          console.log(error.text);
          notify_error();
          setButtonText("Send");
          setButtonDisabled(false);
          e.target.reset();
        }
      );
  };

  return (
    <section id="contact">
      <h2 className="section__title">Get In Touch</h2>

      <div className="contact__container grid">
        <div className="contact__info">
          <h3 className="contact__title">Let's talk about everything !</h3>
          <p className="contact__details">
            Don't like forms? Send me an email . 👋
          </p>
        </div>
        <form className="contact__form" ref={form} onSubmit={sendEmail}>
          <div className="contact__form-group">
            <div className="contact__form-group-div">
              <input
                type="text"
                className="contact__form-input"
                placeholder="Insert your name"
                name="user_name"
                required
              />
            </div>
            <div className="contact__form-group-div">
              <input
                type="email"
                className="contact__form-input"
                placeholder="Insert your email"
                name="user_email"
                required
              />
            </div>
          </div>

          <div className="contact__form-group-div">
            <input
              type="text"
              className="contact__form-input"
              placeholder="Insert your subject"
              name="subject"
              required
            />
          </div>

          <div className="contact__form-group-div contact__form-area">
            <textarea
              name="message"
              id=""
              cols="30"
              rows="10"
              className="contact__form-input"
              placeholder="write your message"
              required
            ></textarea>
          </div>
          <button
            id="sendBtn"
            type="submit"
            className="contact__btn btn primary"
            disabled={buttonDisabled}
          >
            {buttonText}
          </button>
        </form>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </section>
  );
};

export default Contact;
