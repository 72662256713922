import "./skills.css";
import data from "./data";
import React, { useEffect, useRef, useState } from "react";
import NumberAnimation from "../../components/NumberAnimation";
import RevarseNumberAnimation from "../../components/RevarseNumberAnimation";
const Skills = () => {
  const frontData = data.filter((categoryItem) => {
    return categoryItem.category === "front";
  });
  const backendData = data.filter((categoryItem) => {
    return categoryItem.category === "back";
  });
  const mobileData = data.filter((categoryItem) => {
    return categoryItem.category === "mobile";
  });
  const otherData = data.filter((categoryItem) => {
    return categoryItem.category === "other";
  });

  const sectionRef = useRef(null);
  const [shouldChangeWidth, setShouldChangeWidth] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const section = sectionRef.current;
      const sectionOffset = section.offsetTop;
      const sectionHeight = section.offsetHeight;
      const scrollTop = window.scrollY;
      const windowHeight = window.innerHeight;

      if (scrollTop > sectionOffset - windowHeight + sectionHeight / 2) {
        setShouldChangeWidth(true);
      } else {
        setShouldChangeWidth(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section id="skills" className="skills__container">
      <h2 className="section__title">My Skills</h2>

      <div ref={sectionRef} className="skills_section grid">
        <div className="card web">
          <h4>Web Front-end</h4>
          {frontData.map(({ id, image, skill_name, skill_number, prc }) => {
            return (
              <div className="skill">
                <div className="img">
                  <img src={image} alt="" />
                </div>
                <div className="right">
                  <div className="right-top">
                    <h5>{skill_name}</h5>
                    <h5>
                      {shouldChangeWidth ? (
                        <NumberAnimation number={skill_number} />
                      ) : (
                        <RevarseNumberAnimation number={skill_number} />
                      )}{" "}
                      %
                    </h5>
                  </div>

                  <div className="seek-bar">
                    <div
                      className={`seek-bar-fill ${
                        shouldChangeWidth ? "change-width " + prc : ""
                      }`}
                    ></div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="card web">
          <h4>Web Back-end</h4>
          {backendData.map(({ id, image, skill_name, skill_number, prc }) => {
            return (
              <div className="skill">
                <div className="img">
                  <img src={image} alt="" />
                </div>
                <div className="right">
                  <div className="right-top">
                    <h5>{skill_name}</h5>
                    <h5>
                      {shouldChangeWidth ? (
                        <NumberAnimation number={skill_number} />
                      ) : (
                        <RevarseNumberAnimation number={skill_number} />
                      )}{" "}
                      %
                    </h5>
                  </div>

                  <div className="seek-bar">
                    <div
                      className={`seek-bar-fill ${
                        shouldChangeWidth ? "change-width " + prc : ""
                      }`}
                    ></div>
                  </div>
                </div>
              </div>
            );
          })}
      

          <h4>Mobile App</h4>

          {mobileData.map(({ id, image, skill_name, skill_number, prc }) => {
            return (
              <div className="skill">
                <div className="img">
                  <img src={image} alt="" />
                </div>
                <div className="right">
                  <div className="right-top">
                    <h5>{skill_name}</h5>
                    <h5>
                      {shouldChangeWidth ? (
                        <NumberAnimation number={skill_number} />
                      ) : (
                        <RevarseNumberAnimation number={skill_number} />
                      )}{" "}
                      %
                    </h5>
                  </div>

                  <div className="seek-bar">
                    <div
                      className={`seek-bar-fill ${
                        shouldChangeWidth ? "change-width " + prc : ""
                      }`}
                    ></div>
                  </div>
                </div>
              </div>
            );
          })}
              <h4>Other</h4>
          {otherData.map(({ id, image, skill_name, skill_number, prc }) => {
            return (
              <div className="skill">
                <div className="img">
                  <img src={image} alt="" />
                </div>
                <div className="right">
                  <div className="right-top">
                    <h5>{skill_name}</h5>
                    <h5>
                      {shouldChangeWidth ? (
                        <NumberAnimation number={skill_number} />
                      ) : (
                        <RevarseNumberAnimation number={skill_number} />
                      )}{" "}
                      %
                    </h5>
                  </div>

                  <div className="seek-bar">
                    <div
                      className={`seek-bar-fill ${
                        shouldChangeWidth ? "change-width " + prc : ""
                      }`}
                    ></div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Skills;
