const data = [
  {
    id: 1,
    link: "#home",
    title: "Home",
  },
  {
    id: 2,
    link: "#about",
    title: "About",
  },
  {
    id: 3,
    link: "#services",
    title: "Services",
  },
  {
    id: 4,
    link: "#skills",
    title: "Skills",
  },
  {
    id: 5,
    link: "#portfolio",
    title: "Portfolio",
  },
  {
    id: 6,
    link: "#contact",
    title: "Contact",
  },
];

export default data;
