import { SiAdobexd } from "react-icons/si";
import { RiReactjsLine } from "react-icons/ri";
import { FaServer } from "react-icons/fa";
import { AiFillAppstore } from "react-icons/ai";

const data = [
  {
    id: 1,
    icon: <SiAdobexd />,
    title: "UI/UX Design",
    desc: "As a UI/UX designer, I create captivating and user-friendly experiences. With a focus on user-centered design, I craft visually appealing interfaces that meet user needs. Through research, prototyping, and collaboration, I optimize designs for seamless interactions and drive business success.",
  },
  {
    id: 2,
    icon: <RiReactjsLine />,
    title: "Frontend development",
    desc: "I am a front end developer skilled in html, CSS, JavaScript, and React js  I create visually appealing and interactive user interfaces for websites and web applications. My expertise includes structuring content, styling with CSS, adding functionality with JavaScript, and building dynamic applications with React js. I stay updated with industry trends and collaborate effectively to deliver seamless user experiences",
  },
  {
    id: 3,
    icon: <FaServer />,
    title: "Backend development",
    desc: "I am a PHP and Laravel back-end developer who focuses on building scalable and secure web applications. With expertise in server-side logic and API development, I ensure smooth functionality and optimal performance. I excel at problem-solving and collaborate effectively with front-end developers to deliver seamless user experiences.",
  },
  {
    id: 4,
    icon: <AiFillAppstore />,
    title: "App development",
    desc: "I am an Android app developer skilled in Java and XML. I create user-friendly mobile applications with efficient functionality and visually appealing interfaces. My expertise includes API integration and data storage, ensuring high-quality apps that meet user needs.",
  }
]

export default data;
