import React, { useEffect, useState } from 'react';

const RevarseNumberAnimation = ({ number }) => {
  const [animatedNumber, setAnimatedNumber] = useState(0);

  useEffect(() => {
    const animateNumber = () => {
      const animationDuration = 800; // Duration of the animation in milliseconds
      const animationStep = Math.ceil(number / (animationDuration / 10)); // Decrement value for each animation frame
      let currentValue = number;

      const interval = setInterval(() => {
        if (currentValue <= 0) {
          setAnimatedNumber(0);
          clearInterval(interval);
        } else {
          setAnimatedNumber(currentValue);
          currentValue -= animationStep;
        }
      }, 10);

      return () => {
        clearInterval(interval);
      };
    };

    animateNumber();
  }, [number]);

  return <span>{animatedNumber}</span>;
};

export default RevarseNumberAnimation;