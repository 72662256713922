import Image1 from '../../assets/avatar-1.svg'
import Image2 from '../../assets/avatar-2.svg'
import Image3 from '../../assets/avatar-3.svg'
const data = [
    {
      id: 1,
      image: "https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/d4cae9da907b2074550158fe43e48f48-1663367437804/f6b5522b-ad84-4cde-9a59-31cdb4a79de5.jpg",
      title: "esigmax",
      subtitle: "Usa",
      comment:
        "The seller has been great in creating exactly what I asked for. He went overboard in providing more than I had expected. He is a true professional in his work and I highly recommend him to anyone needing his services. I can't say enough about his quality of work, exceptional communication and the beautiful app he created for me. Definitely will use again!",
    },
    {
      id: 2,
      image: Image2,
      title: "techsynectics",
      subtitle: "Australia",
      comment:
        "It's great working with Ayoub. I will definitely offer him more projects👍",
    },
    {
      id: 3,
      image: "https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/19928c1448571c980671822f80a9a256-1664700565735/f80e313d-c5d4-4c3d-be46-468f0ec77e3b.jfif",
      title: "adnandevolper1",
      subtitle: "Pakistan",
      comment:
        "speed delivery excellent app developer.I would really recommend him for app development work.l am looking for more services from this developer. 100,% expert in App development 🙂",
    },
    {
      id: 4,
      image: Image3,
      title: "Christiana",
      subtitle: "Usa",
      comment:
        "Quality and professional services. I highly recommend.",
    },
    {
      id: 4,
      image: Image1,
      title: "rachidrachid949",
      subtitle: "Morocco",
      comment:
        "everything I asked for was done great job",
    }
    
  ];

  export default data;