import './footer.css'
import data from '../header/data';

const Footer = () => {
    return (
        <footer>
           <div className="footer__container">
            <div className="copyright">
                ©️2023 developed by <span className="author__name">Ayoub Es-sarghini</span> All Right Reserved
            </div>
            <div className="social__media">
            {
               data.map(item => <a href={item.link} key={item.id} target="_blank" rel="noreferrer">{item.icon}</a>)
            }
            </div>
           </div>

           <div className="menu__field">
               
           </div>
        </footer>
    );
}

export default Footer;
