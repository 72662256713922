import Work1 from "../../assets/images/work/work1.png";
import Work2 from "../../assets/images/work/work2.png";
import Work3 from "../../assets/images/work/work3.png";
import Work4 from "../../assets/images/work/work4.png";
import Work5 from "../../assets/images/work/work5.png";
import Work6 from "../../assets/images/work/work6.png";
import Work8 from "../../assets/images/work/work8.png";
import Work9 from "../../assets/images/work/work9.png";
import Work10 from "../../assets/images/work/work10.png";
import Work11 from "../../assets/images/work/work11.png";


const Menu = [
  {
    id: 1,
    image: Work1,
    title: "ecommerce app ShopIn",
    url:'#',
    category: "Mobile",
  },
  {
    id: 2,
    image: Work2,
    title: "Between us Chat app",
    url:'#',
    category: "Mobile",
  },
  {
    id: 3,
    image: Work3,
    title: "ecommerce app Multi vendor",
    url:'https://shop.khawatiri.com/',
    category: "Web",
  },
  {
    id: 4,
    image: Work4,
    title: "Pixels clone",
    url:'https://pixels-clone-aessarghini.netlify.app/',
    category: "Web",
  },
  {
    id: 5,
    image: Work5,
    title: "Facebook clone",
    url:'#',
    category: "Mobile",
  },
  {
    id: 6,
    image: Work6,
    title: "Inventory management using Barcode",
    url:'#',
    category: "Mobile",
  },
  {
    id: 7,
    image: Work11,
    title: "School management",
    url:'#',
    category: "Web",
  },
  {
    id: 8,
    image: Work8,
    title: "Trippy Travel website",
    url:'#',
    category: "Web",
  },
  {
    id: 9,
    image: Work9,
    title: "Let's chat Chat app",
    url:'https://lets-chat.khawatiri.com/',
    category: "Web",
  },
  {
    id: 10,
    image: Work10,
    title: "Whatsapp clone",
    url:'#',
    category: "Mobile",
  }
]

export default Menu;
